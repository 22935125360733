export const commonValidations = {
  required: "required",
  noSpecialCharacters: "noSpecialCharacters",
  noSpaces: "noSpaces",
  onlyEnglishDigits: "onlyEnglishDigits",
  noAlphabeticalCharacters: "noAlphabeticalCharacters",
  onlyEnglishAlphanumeric: "onlyEnglishAlphanumeric",
  canotStartWithSpaces: "canotStartWithSpaces",
};

export const nameAndAddressValidations = {
  noNumbers: "noNumbers",
  onlyEnglish: "onlyEnglish",
};

export const passportNumberValidation = {
  length: "length",
};

export const dateValidations = {
  enterValid: "enterValid",
  expiredPassport: "expiredPassport",
  expired: "expired",
  notInTheFuture: "notInTheFuture",
  ageRestriction: "ageRestriction",
};

export const civilIDValidations = {
  civilIdlength: "civilIdlength",
};

export const civilIDSerialNumberValidations = {
  civilSeriallength: "civilSeriallength",
};
export const emailAndMobileValidations = {
  enterValidEmail: "enterValidEmail",
  kuwaitNumberRules: "kuwaitNumberRules",
  enterValidConfirmedEmail: "enterValidConfirmedEmail",
};

export const OTPNumberValidations = {
  Otplength: "Otplength",
};

export const ibanValidations = {
  IBANRequired: "IBANRequired",
  ibanNumberLength: "ibanNumberLength",
};

export const uploadedFileValidations = {
  exceedsSizeLimit: "exceedsSizeLimit",
  noFileSelected: "noFileSelected",
  unSupportedUploadedFileFormat: "unSupportedUploadedFileFormat",
};
