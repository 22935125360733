import ValidationUtil from "../utils/validation/validation-utils";
import DateUtils from "../utils/date";
import {
  nameAndAddressValidations,
  dateValidations,
  commonValidations,
  civilIDValidations,
  civilIDSerialNumberValidations,
  passportNumberValidation,
  emailAndMobileValidations,
  ibanValidations,
} from "../utils/validation/validation-messages";
import moment from "moment";

class KYCValidator {
  static validateField(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    if (ValidationUtil.startWithWhiteSpaces(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.canotStartWithSpaces,
      };
    } else if (ValidationUtil.isEmpty(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    }

    return validationResult;
  }

  static validateTextField(value, isOptional = false) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    if (ValidationUtil.startWithWhiteSpaces(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.canotStartWithSpaces,
      };
    } else if (ValidationUtil.isEmpty(value) && !isOptional) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (value && !ValidationUtil.isAlphaNumericWithSpaces(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishAlphanumeric,
      };
    }
    return validationResult;
  }

  static validateOptionalCompanyNames(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    if (ValidationUtil.startWithWhiteSpaces(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.canotStartWithSpaces,
      };
    } else if (value && !ValidationUtil.isAlphaNumericWithSpaces(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishAlphanumeric,
      };
    }
    return validationResult;
  }

  static validateNames(value, isRequired = true) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    if (ValidationUtil.startWithWhiteSpaces(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.canotStartWithSpaces,
      };
    } else if (ValidationUtil.isEmpty(value) && isRequired) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.containsNameSpecialChars(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpecialCharacters,
      };
    } else if (ValidationUtil.containsNumber(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: nameAndAddressValidations.noNumbers,
      };
    } else if (ValidationUtil.isNotEnglishCharacters(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: nameAndAddressValidations.onlyEnglish,
      };
    }

    return validationResult;
  }

  static validateCustomerName(value, isRequired = true) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    if (ValidationUtil.startWithWhiteSpaces(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.canotStartWithSpaces,
      };
    } else if (ValidationUtil.isEmpty(value) && isRequired) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.containsNameSpecialChars(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpecialCharacters,
      };
    } else if (ValidationUtil.containsNumber(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: nameAndAddressValidations.noNumbers,
      };
    } else if (ValidationUtil.isNotEnglishCharacters(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: nameAndAddressValidations.onlyEnglish,
      };
    }

    return validationResult;
  }

  static validateDOB(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    const parsedDate = moment(value, "DD/MM/YYYY", true);

    if (!value) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (!parsedDate.isValid()) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: dateValidations.enterValid,
      };
    } else if (DateUtils.isDateInFuture(parsedDate)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: dateValidations.notInTheFuture,
      };
    } else if (DateUtils.isUnder18(parsedDate)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: dateValidations.ageRestriction,
      };
    }

    return validationResult;
  }

  static validateCivilIdNumber(value, ignoreLengthValidation = false) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    if (ValidationUtil.isEmpty(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.containsWhiteSpace(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpaces,
      };
    } else if (ValidationUtil.containsSpecialCharacters(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpecialCharacters,
      };
    } else if (ValidationUtil.containsArabicNumbers(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishDigits,
      };
    } else if (ValidationUtil.containsAlphabeticalChars(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noAlphabeticalCharacters,
      };
    } else if (
      value &&
      !ValidationUtil.isExactLength(value, 12) &&
      !ignoreLengthValidation
    ) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: civilIDValidations.length,
      };
    }

    return validationResult;
  }

  static validateSerialIdNumber(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    if (ValidationUtil.isEmpty(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.containsWhiteSpace(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpaces,
      };
    } else if (ValidationUtil.containsSpecialCharacters(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpecialCharacters,
      };
    } else if (ValidationUtil.containsArabicNumbers(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishDigits,
      };
    } else if (ValidationUtil.containsAlphabeticalChars(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noAlphabeticalCharacters,
      };
    } else if (value && !ValidationUtil.isExactLength(value, 10)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: civilIDSerialNumberValidations.length,
      };
    }

    return validationResult;
  }

  static validateCivilIdExpiryDate(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    const parsedDate = moment(value, "DD/MM/YYYY", true);

    if (!value) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (!parsedDate.isValid()) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: dateValidations.enterValid,
      };
    } else if (value && !DateUtils.isDateInFuture(parsedDate)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: dateValidations.expired,
      };
    }

    return validationResult;
  }

  static validatePassportNumber(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    if (ValidationUtil.isEmpty(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.containsWhiteSpace(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpaces,
      };
    } else if (ValidationUtil.containsSpecialCharacters(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpecialCharacters,
      };
    } else if (value && !ValidationUtil.isAlphaNumeric(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishAlphanumeric,
      };
    } else if (value && !ValidationUtil.isLengthInRange(value, 1, 11)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: passportNumberValidation.length,
      };
    }

    return validationResult;
  }

  static validatePassportExpiryDate(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    const parsedDate = moment(value, "DD/MM/YYYY", true);

    if (!value) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (!parsedDate.isValid()) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: dateValidations.enterValid,
      };
    } else if (value && !DateUtils.isDateInFuture(parsedDate)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: dateValidations.expiredPassport,
      };
    }

    return validationResult;
  }

  static validateEmail(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    if (ValidationUtil.startWithWhiteSpaces(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.canotStartWithSpaces,
      };
    } else if (ValidationUtil.isEmpty(value) || !value) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (value && !ValidationUtil.validateEmail(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: emailAndMobileValidations.enterValidEmail,
      };
    }

    return validationResult;
  }

  static validateMobileNumber(value, code) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    const kuwaitiCountryCodeNumber = "+965";

    if (ValidationUtil.isEmpty(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.containsSpecialCharacters(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpecialCharacters,
      };
    } else if (ValidationUtil.containsArabicNumbers(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishDigits,
      };
    } else if (ValidationUtil.containsWhiteSpace(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpaces,
      };
    } else if (ValidationUtil.containsAlphabeticalChars(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noAlphabeticalCharacters,
      };
    } else if (
      code === kuwaitiCountryCodeNumber &&
      value &&
      !ValidationUtil.isKuwaitiNumber(value)
    ) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: emailAndMobileValidations.kuwaitNumberRules,
      };
    }

    return validationResult;
  }

  static validateTaxPayerId(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    if (ValidationUtil.isEmpty(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.containsWhiteSpace(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpaces,
      };
    } else if (ValidationUtil.containsSpecialCharacters(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpecialCharacters,
      };
    } else if (value && !ValidationUtil.isAlphaNumeric(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishAlphanumeric,
      };
    }

    return validationResult;
  }

  static validateAddress(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    if (ValidationUtil.startWithWhiteSpaces(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.canotStartWithSpaces,
      };
    } else if (ValidationUtil.isEmpty(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.isNotEnglishCharacters(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: nameAndAddressValidations.onlyEnglish,
      };
    }

    return validationResult;
  }

  static validateSignatureDate(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    const momentInvalidDateMessage = "Invalid Date";
    if (String(value) === momentInvalidDateMessage) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: dateValidations.enterValid,
      };
    }

    return validationResult;
  }

  static validateIncomeDetailsText(value, isOptional = false) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    if (ValidationUtil.startWithWhiteSpaces(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.canotStartWithSpaces,
      };
    } else if (ValidationUtil.isEmpty(value) && !isOptional) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (value && ValidationUtil.isNotEnglishCharacters(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishAlphanumeric,
      };
    }

    return validationResult;
  }
  static validateIbanNumber(value, validationData) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    const { min = 0, max = 0, bankNameValue } = validationData;

    if (bankNameValue && ValidationUtil.isEmpty(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: ibanValidations.IBANRequired,
      };
    } else if (bankNameValue && !ValidationUtil.isAlphaNumeric(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishAlphanumeric,
      };
    } else if (bankNameValue && (value.length < min || value.length > max)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: ibanValidations.ibanNumberLength,
      };
    }

    return validationResult;
  }
}

export default KYCValidator;
