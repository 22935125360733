import React, { useEffect } from "react";
import NumericInputField from "../../../widgets/inputs/numeric-format-input";
import { convertStringToNumber } from "../../../utils";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";

const WealthAmount = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.WEALTH_AMOUNT;

  const {
    [fieldKey]: { value, error },
    ...wealthAmounts
  } = data;

  const calculateTotalWealth = (
    jobValue = 0,
    inheritanceValue = 0,
    investmentsValue = 0,
    savingValue = 0,
    saleValue = 0,
    otherValue = 0
  ) =>
    convertStringToNumber(jobValue) +
    convertStringToNumber(inheritanceValue) +
    convertStringToNumber(investmentsValue) +
    convertStringToNumber(savingValue) +
    convertStringToNumber(saleValue) +
    convertStringToNumber(otherValue);

  useEffect(() => {
    const totalWealth = calculateTotalWealth(
      wealthAmounts[KycKeys.WEALTH_AMOUNT_JOB]?.value,
      wealthAmounts[KycKeys.WEALTH_AMOUNT_INHERITANCE]?.value,
      wealthAmounts[KycKeys.WEALTH_AMOUNT_INVESTMENTS]?.value,
      wealthAmounts[KycKeys.WEALTH_AMOUNT_SAVING]?.value,
      wealthAmounts[KycKeys.WEALTH_AMOUNT_SALE]?.value,
      wealthAmounts[KycKeys.WEALTH_AMOUNT_OTHER]?.value
    );

    if (totalWealth !== value) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          error: "",
          value: totalWealth,
        },
      }));
    }
  }, [
    wealthAmounts[KycKeys.WEALTH_AMOUNT_JOB]?.value,
    wealthAmounts[KycKeys.WEALTH_AMOUNT_INHERITANCE]?.value,
    wealthAmounts[KycKeys.WEALTH_AMOUNT_INVESTMENTS]?.value,
    wealthAmounts[KycKeys.WEALTH_AMOUNT_SAVING]?.value,
    wealthAmounts[KycKeys.WEALTH_AMOUNT_SALE]?.value,
    wealthAmounts[KycKeys.WEALTH_AMOUNT_OTHER]?.value,
    wealthAmounts[KycKeys.WEALTH_SOURCE]?.value,
    value,
  ]);

  return (
    <NumericInputField
      name={"wealthAmount"}
      inputContent={translator("kuwaitCurrency")}
      inputContentAdornment="start"
      isDisabled
      label={translator("wealthAmountLabel")}
      value={value}
      errorMessage={error}
    />
  );
};
WealthAmount.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};
export default WealthAmount;
