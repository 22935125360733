import moment from "moment";

class DateUtils {
  static formatCounterTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }

  static formatDate(date, format = "DD/MM/yyyy") {
    if (date) {
      return moment(date).format(format);
    }
    return "";
  }

  static isDateInFuture(date) {
    let d = date;
    if (d) {
      if (!(d instanceof moment)) {
        d = moment(d);
      }
      const today = moment.now();
      return moment(d).isAfter(today);
    }
    return false;
  }

  static isDateLessThanOrEqualToToday(value, condition) {
    var providedDate = new Date(value);
    var today = new Date();

    providedDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (condition === "lower") {
      return providedDate <= today;
    }

    return providedDate.getTime() === today.getTime();
  }

  static addYearsToDate(date, yearsToAdd) {
    if (!date) {
      return "";
    }

    const providedDate = new Date(date);
    providedDate.setFullYear(providedDate.getFullYear() + yearsToAdd);

    return moment(providedDate).format("YYYY-MM-DD");
  }
  static currentDateInSeconds() {
    return Math.floor(Date.now() / 1000);
  }
  static isUnder18(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    if (
      today.getMonth() < dob.getMonth() ||
      (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age < 18;
  }
}

export default DateUtils;
