import React from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import CustomSelect from "../../../widgets/inputs/custom-select";

const Nationality = ({ data, setKycData, countries = [], translator }) => {
  const fieldKey = KycKeys.NATIONALITY;
  const passportNumberKey = KycKeys.PASSPORT_NUMBER;
  const passportCountryKey = KycKeys.PASSPORT_COUNTRY;
  const passportExpiryDateKey = KycKeys.PASSPORT_EXPIRY_DATE;
  const otherNationlityKey = KycKeys.OTHER_NATIONALITIES;

  const {
    [fieldKey]: { value, error, isDisabled },
    [otherNationlityKey]: { value: otherNationalityValue },
  } = data;

  const countriesList = countries.filter(
    (country) => !otherNationalityValue.includes(country["alpha-2"])
  );

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value,
      },
      [passportNumberKey]: {
        ...prev[passportNumberKey],
        value: "",
        error: "",
      },
      [passportCountryKey]: {
        ...prev[passportCountryKey],
        value,
        error: "",
      },
      [passportExpiryDateKey]: {
        ...prev[passportExpiryDateKey],
        value: "",
        error: "",
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  const countriesOptions = Object.keys(countriesList)?.map((key) => ({
    label: countriesList[key].name,
    value: countriesList[key]["alpha-2"],
  }));

  return (
    <CustomSelect
      name={"nationality"}
      label={translator("nationality")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
      inputOptions={countriesOptions}
      isDisabled={isDisabled}
    />
  );
};

Nationality.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  translator: PropTypes.func.isRequired,
};

export default Nationality;
