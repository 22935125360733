import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import InputField from "../../../widgets/inputs/primary-input";

const TaxPayerIdOne = ({
  data,
  setKycData,
  isKuwaityCitizen,
  customerCivilIdNumber,
  translator,
  isUSCitizenByAnyNationality,
}) => {
  const fieldKey = KycKeys.TAX_PAYER_ID_1;
  const paysTaxesInOtherCountryKey = KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY;
  const taxPayerIdentificationKey = KycKeys.TAX_PAYER_IDENTIFICATION;
  const usCitizenKey = KycKeys.US_CITIZEN;
  const {
    [fieldKey]: { value, error, isDisabled },
    [paysTaxesInOtherCountryKey]: { value: paysTaxesInOtherCountryValue },
    [usCitizenKey]: { value: usCitizenValue },
    [taxPayerIdentificationKey]: { value: taxPayerIdentificationValue },
  } = data;

  const isUsCitizen = usCitizenValue;
  const isVisible = paysTaxesInOtherCountryValue;
  const disabled =
    usCitizenValue ||
    isUSCitizenByAnyNationality ||
    isKuwaityCitizen ||
    isDisabled;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateTaxPayerId(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: value,
      },
    }));
  };

  const handleChange = ({ target: { value } }) => {
    validateField(value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  useEffect(() => {
    if (isKuwaityCitizen) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          value: customerCivilIdNumber,
        },
      }));
      return;
    }

    if (isUsCitizen || isUSCitizenByAnyNationality) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          value: taxPayerIdentificationValue,
          error: "",
        },
      }));
      return;
    }

    if (!isVisible) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          value: "",
          error: "",
        },
      }));
      return;
    }
  }, [isUsCitizen, taxPayerIdentificationValue, isKuwaityCitizen]);

  if (!isVisible) {
    return null;
  }

  return (
    <InputField
      name={"taxIdOne"}
      isDisabled={disabled}
      label={translator("taxIdOne")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

TaxPayerIdOne.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  isKuwaityCitizen: PropTypes.bool.isRequired,
  customerCivilIdNumber: PropTypes.string.isRequired,
  isUSCitizenByAnyNationality: PropTypes.bool.isRequired,
  translator: PropTypes.func.isRequired,
};

export default TaxPayerIdOne;
