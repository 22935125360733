import React, { useEffect } from "react";
import NumericInputField from "../../../widgets/inputs/numeric-format-input";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import { wealthSourceEnums } from "../../../enums/income-and-wealth";
import PropTypes from "prop-types";
import { formatValue } from "../../../utils";

const WealthAmountJob = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.WEALTH_AMOUNT_JOB;
  const wealthSourceKey = KycKeys.WEALTH_SOURCE;

  const {
    [fieldKey]: { value, error, isVisible: isJobAmountVisible },
    [wealthSourceKey]: { value: wealthSourceValue },
  } = data;

  const isWealthSourceJobExist = wealthSourceValue.some(
    (item) => item === wealthSourceEnums.JOB_INDEMNITY
  );

  const isVisible = isJobAmountVisible || isWealthSourceJobExist;

  const validateField = (value) => {
    let formattedValue = "";
    if (value !== "") {
      formattedValue = formatValue(value);
    }

    const fieldErrorObject = KYCValidator.validateField(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value: formattedValue,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <NumericInputField
      name={"wealthAmountJob"}
      inputContent={translator("kuwaitCurrency")}
      inputContentAdornment="start"
      isDisabled={false}
      label={translator("wealthAmountJobLabel")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};
WealthAmountJob.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};
export default WealthAmountJob;
