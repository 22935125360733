import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import InputField from "../../../widgets/inputs/primary-input";

const UsAddressThree = ({
  data,
  setKycData,
  translator,
  isUSCitizenByAnyNationality,
}) => {
  const fieldKey = KycKeys.US_ADDRESS_3;
  const usCitizenKey = KycKeys.US_CITIZEN;
  const {
    [fieldKey]: { value, error, isDisabled },
    [usCitizenKey]: { value: usCitizenValue },
  } = data;

  const isVisible = usCitizenValue || isUSCitizenByAnyNationality;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateTextField(value, true);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: value,
      },
    }));
  };

  const handleChange = ({ target: { value } }) => {
    validateField(value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <InputField
      name={"usAddress3"}
      isDisabled={isDisabled}
      label={translator("usAddress3")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

UsAddressThree.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  isUSCitizenByAnyNationality: PropTypes.bool.isRequired,
};

export default UsAddressThree;
