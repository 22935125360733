import {
  incomeSourceEnums,
  wealthSourceEnums,
  clientClassificationStatusEnums,
} from "../../enums/income-and-wealth";
import employmentStatusEnums from "../../enums/employment-status-enum";
import { locale } from "../../localiztion";

const getIsIncomeValueDisabled = (option, employmentStatus) => {
  if (
    employmentStatus === employmentStatusEnums.EMPLOYED &&
    option === incomeSourceEnums.JOB
  ) {
    return true;
  } else if (
    employmentStatus === employmentStatusEnums.SELF_EMPLOYED &&
    option === incomeSourceEnums.PRIVATE_BUSINESS
  ) {
    return true;
  } else if (
    employmentStatus === employmentStatusEnums.RETIRED &&
    option === incomeSourceEnums.PENSION_SOCIAL_SECURITY
  ) {
    return false;
  } else {
    return false;
  }
};

const getPossibleIncomeSourceOptions = (options, employmentStatus) => {
  let possibleOptions = [];
  if (
    employmentStatus === employmentStatusEnums.UNEMPLOYED ||
    employmentStatus === employmentStatusEnums.STUDENT ||
    employmentStatus === employmentStatusEnums.RETIRED
  ) {
    possibleOptions = [
      {
        label: options[incomeSourceEnums.INVESTMENT],
        value: incomeSourceEnums.INVESTMENT,
        isDisabled: getIsIncomeValueDisabled(
          incomeSourceEnums.INVESTMENT,
          employmentStatus
        ),
      },
      {
        label: options[incomeSourceEnums.REAL_ESTATE],
        value: incomeSourceEnums.REAL_ESTATE,
        isDisabled: getIsIncomeValueDisabled(
          incomeSourceEnums.REAL_ESTATE,
          employmentStatus
        ),
      },
      {
        label: options[incomeSourceEnums.OTHER],
        value: incomeSourceEnums.OTHER,
        isDisabled: getIsIncomeValueDisabled(
          incomeSourceEnums.OTHER,
          employmentStatus
        ),
      },
    ];
    const pensionItem =
      employmentStatus === employmentStatusEnums.RETIRED
        ? {
            label: options[incomeSourceEnums.PENSION_SOCIAL_SECURITY],
            value: incomeSourceEnums.PENSION_SOCIAL_SECURITY,
            isDisabled: getIsIncomeValueDisabled(
              incomeSourceEnums.PENSION_SOCIAL_SECURITY,
              employmentStatus
            ),
          }
        : null;
    possibleOptions = [...possibleOptions, pensionItem].filter(Boolean);
    return possibleOptions;
  }
  return Object.keys(options).map((key) => ({
    label: options[key],
    value: key,
    isDisabled: getIsIncomeValueDisabled(key, employmentStatus),
  }));
};

const getDefaultIncomeSourceValues = (options, employmentStatus) => {
  const defaultValueMap = {
    [employmentStatusEnums.EMPLOYED]: [
      {
        label: options[incomeSourceEnums.JOB],
        value: incomeSourceEnums.JOB,
      },
    ],
    [employmentStatusEnums.SELF_EMPLOYED]: [
      {
        label: options[incomeSourceEnums.PRIVATE_BUSINESS],
        value: incomeSourceEnums.PRIVATE_BUSINESS,
      },
    ],
    [employmentStatusEnums.RETIRED]: [
      {
        label: options[incomeSourceEnums.PENSION_SOCIAL_SECURITY],
        value: incomeSourceEnums.PENSION_SOCIAL_SECURITY,
      },
    ],
  };

  return defaultValueMap[employmentStatus] || [];
};

const getIncomeSourceValue = (
  currentValues,
  defaultValues,
  possibleOptions = []
) =>
  currentValues
    .filter(
      (option) =>
        !defaultValues.some(
          (fixedOption) =>
            fixedOption.label === option.label &&
            fixedOption.value === option.value
        ) &&
        possibleOptions.some(
          (possibleOption) =>
            possibleOption.label === option.label &&
            possibleOption.value === option.value
        )
    )
    .map((filteredOption) => filteredOption.value);

const getIsWealthOfSourceValueDisabled = (option, hasInvestment) =>
  hasInvestment && option === wealthSourceEnums.INVESTMENT;

const getDefaultWealthSourceDefaultValues = (options, hasInvestment) => {
  const defaultValue = [];
  if (hasInvestment) {
    defaultValue.push({
      label: options[wealthSourceEnums.INVESTMENT],
      value: wealthSourceEnums.INVESTMENT,
    });
  }

  return defaultValue;
};

const getWealthSourceValue = (currentValues, defaultValues) =>
  currentValues
    .filter(
      (option) =>
        !defaultValues.some(
          (fixedOption) =>
            fixedOption.label === option.label &&
            fixedOption.value === option.value
        )
    )
    .map((filteredOption) => filteredOption.value);

const getClientClassificationResult = (selectedOptions) => {
  const {
    assetsValue,
    transactionsPastTwoYearsValue,
    financialSectorYearsExperienceValue,
  } = selectedOptions;

  const classificationResultMapping = {
    assetsValue: {
      above_100000_kd: clientClassificationStatusEnums.QUALIFIED_PROFESSIONAL,
      below_100000_kd: clientClassificationStatusEnums.RETAIL,
      not_applicable: clientClassificationStatusEnums.RETAIL,
    },
    transactionsPastTwoYearsValue: {
      above_250000_kd: clientClassificationStatusEnums.QUALIFIED_PROFESSIONAL,
      below_250000_kd: clientClassificationStatusEnums.RETAIL,
      not_applicable: clientClassificationStatusEnums.RETAIL,
    },
    financialSectorYearsExperienceValue: {
      greater_than_one_year:
        clientClassificationStatusEnums.QUALIFIED_PROFESSIONAL,
      less_than_one_year: clientClassificationStatusEnums.RETAIL,
      not_applicable: clientClassificationStatusEnums.RETAIL,
    },
  };

  const results = [
    classificationResultMapping.assetsValue[assetsValue],
    classificationResultMapping.transactionsPastTwoYearsValue[
      transactionsPastTwoYearsValue
    ],
    classificationResultMapping.financialSectorYearsExperienceValue[
      financialSectorYearsExperienceValue
    ],
  ];

  const allQualified = results.some(
    (result) =>
      result === clientClassificationStatusEnums.QUALIFIED_PROFESSIONAL
  );

  return allQualified
    ? clientClassificationStatusEnums.QUALIFIED_PROFESSIONAL
    : clientClassificationStatusEnums.RETAIL;
};

const getClientClassificationLabel = (value) => {
  if (!value) {
    return;
  }
  return value === clientClassificationStatusEnums.RETAIL
    ? locale.retailLabel
    : locale.qualifiedProfessionalLabel;
};

export {
  getPossibleIncomeSourceOptions,
  getDefaultIncomeSourceValues,
  getIsIncomeValueDisabled,
  getIncomeSourceValue,
  getIsWealthOfSourceValueDisabled,
  getDefaultWealthSourceDefaultValues,
  getWealthSourceValue,
  getClientClassificationResult,
  getClientClassificationLabel,
};
