import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { capitalizeWords } from "../../../utils";

const USPassportFullName = ({
  data,
  setKycData,
  translator,
  isUSCitizenByAnyNationality,
}) => {
  const fieldKey = KycKeys.FULL_NAME_AS_IN_US_PASSPORT;
  const usCitizenKey = KycKeys.US_CITIZEN;

  const {
    [fieldKey]: { value, error, isDisabled },
    [usCitizenKey]: { value: usCitizenValue },
  } = data;

  const isVisible = usCitizenValue || isUSCitizenByAnyNationality;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateCustomerName(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  const handleBlur = ({ target: { value } }) => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: "",
        value: capitalizeWords(value),
      },
    }));
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);
  if (!isVisible) {
    return null;
  }

  return (
    <InputField
      label={translator("USPassportFullName")}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      errorMessage={error}
      isDisabled={isDisabled}
    />
  );
};

USPassportFullName.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  isUSCitizenByAnyNationality: PropTypes.bool.isRequired,
};

export default USPassportFullName;
