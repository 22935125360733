import React, { useEffect, useState } from "react";
import KYCValidator from "../../../helpers/validation";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { countriesEnums } from "../../../enums/general";

const USPassportNumber = ({
  data,
  setKycData,
  translator,
  isUSCitizenByAnyNationality,
}) => {
  const fieldKey = KycKeys.US_PASSPORT_NUMBER;
  const usCitizenKey = KycKeys.US_CITIZEN;
  const passportNumberKey = KycKeys.PASSPORT_NUMBER;
  const nationalityKey = KycKeys.NATIONALITY;

  const {
    [fieldKey]: { value: usPassportNumberValue, error, isDisabled },
    [usCitizenKey]: { value: usCitizenValue },
    [passportNumberKey]: { value: passportNumberValue },
    [nationalityKey]: { value: nationalityValue },
  } = data;
  const [passportNumberVal, setPassportNumberVal] = useState(
    usPassportNumberValue
  );
  const isUSNationality = nationalityValue === countriesEnums.UNITEDSTATES;
  const isVisible = usCitizenValue || isUSCitizenByAnyNationality;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validatePassportNumber(value);
    setPassportNumberVal(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  useEffect(() => {
    if (isUSNationality) {
      setPassportNumberVal(passportNumberValue);
    }
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value: isUSNationality ? passportNumberValue : usPassportNumberValue,
        isDisabled: isUSNationality,
        isVisible: isVisible,
      },
    }));
  }, [isUSNationality]);

  if (!isVisible) {
    return null;
  }

  return (
    <InputField
      label={translator("USPassportNumber")}
      onChange={handleChange}
      value={passportNumberVal}
      errorMessage={error}
      isDisabled={isDisabled}
    />
  );
};

USPassportNumber.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  isUSCitizenByAnyNationality: PropTypes.bool.isRequired,
};

export default USPassportNumber;
