import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import styles from "./input-field.module.scss";
import "../../../../src/theme/spacing.scss";
import { useTranslation } from "react-i18next";

const NumericInputField = ({
  name,
  icon,
  inputadornment,
  onIconClick,
  inputContent,
  inputContentAdornment,
  isDisabled,
  label,
  onChange,
  value,
  errorMessage,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [direction, setDirection] = useState("rtl");

  const inputRef = useRef(null);
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    setDirection(dir);
  }, [i18n, i18n.language]);

  useEffect(() => {
    if (value) {
      setIsActive(true);
    }
  }, [value]);

  const handleFocus = () => {
    setIsFocused(true);
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (!value) {
      setIsActive(false);
    }
  };

  const handleClickLabel = () => {
    if (!isActive) {
      setIsActive(true);
      setIsFocused(true);
      inputRef.current.focus();
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={`${styles.inputContainer} ${
          errorMessage ? styles.error : ""
        }`}
      >
        {icon && (
          <img
            src={icon}
            alt="icon"
            className={styles.inputIcon}
            onClick={onIconClick}
            style={{
              left:
                inputadornment === "start" ? "var(--large-spacing)" : "auto",
              right: inputadornment === "end" ? "var(--large-spacing)" : "auto",
            }}
          />
        )}
        {inputContent && (
          <span
            className={styles.inputContent}
            style={{
              left:
                inputContentAdornment === "start"
                  ? "var(--large-spacing)"
                  : "auto",
              right:
                inputContentAdornment === "end"
                  ? "var(--large-spacing)"
                  : "auto",
            }}
          >
            {inputContent}
          </span>
        )}

        <label
          className={`${styles.floatingLabel} ${
            isActive || value || inputContent ? styles.active : ""
          } ${isFocused && isActive ? styles.focused : ""} ${
            errorMessage ? styles.error : ""
          }`}
          onClick={handleClickLabel}
          style={{
            left: ` ${direction === "ltr" ? (isActive || isFocused || inputContent ? "var(--medium2-spacing)" : inputadornment === "start" ? "35px" : "var(--medium2-spacing)") : "auto"}`,
            right: ` ${direction === "rtl" ? (isActive || isFocused || inputContent ? "var(--medium2-spacing)" : inputadornment === "start" ? "35px" : "var(--medium2-spacing)") : "auto"}`,
          }}
        >
          {label}
        </label>

        <NumericFormat
          name={name}
          className={`${styles.inputField}`}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onValueChange={({ value }) => onChange({ target: { value } })}
          disabled={isDisabled}
          value={value}
          getInputRef={inputRef}
          {...rest}
          style={{
            paddingLeft:
              inputadornment === "start"
                ? "var(--double-extra-large-spacing)"
                : inputContentAdornment === "start"
                  ? "var(--extra-extra-large-spacing)"
                  : "var(--large-spacing)",

            paddingRight:
              inputContentAdornment === "end"
                ? "var(--extra-extra-large-spacing)"
                : "var(--double-extra-large-spacing)",
          }}
          decimalScale={2}
          allowNegative={false}
          thousandSeparator
          fixedDecimalScale
          valueIsNumericString
        />
      </div>

      {errorMessage && (
        <span className={styles.errorMessage}>{t(errorMessage)}</span>
      )}
    </div>
  );
};

NumericInputField.defaultProps = {
  onChange: () => {},
};

NumericInputField.propTypes = {
  icon: PropTypes.string,
  inputadornment: PropTypes.string,
  inputContent: PropTypes.string,
  inputContentAdornment: PropTypes.string,
  onIconClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  errorMessage: PropTypes.string,
};

export default NumericInputField;
